
// example: <div class="js-validate-text js-lehgth js-required" data-length="5"><input type="text"></div>

// validation:
//             required: js-required
//             email: js-email
//             phone: js-phone
//             length: js-length  -garumu norada atributa: data-length="5"
//             min-value: js-min-value data-min-value
//             max-value: js-max-value data-max-value
//             password: type="password"   class: js-password, js-password-repeat

import $ from 'jquery'


export function validateForm (form, set_error) {
  let validated = 1
  let text_fields = form.find('.js-validate-text')
  let password_fields = form.find('.js-validate-password, .js-validate-password-repeat')
  let checkbox_radio = form.find('.js-validate-checked')
  let files = form.find('.js-validate-file')
  let selects = form.find('.js-validate-select')

  text_fields.each(function (index, el) {
    if (!validateTextFields($(el), set_error)) validated = 0
  })
  password_fields.each(function (index, el) {
    if (!validateInputPassword($(el), set_error)) validated = 0
  })
  checkbox_radio.each(function (index, el) {
    if (!validateChecked($(el), set_error)) validated = 0
  })
  files.each(function (index, el) {
    if (!validateInputFiles($(el), set_error)) validated = 0
  })
  selects.each(function (index, el) {
    if (!validateInputSelect($(el), set_error)) validated = 0
  })


  // // check if date selected in registration form
  // if (form.hasClass('js-reservation-form')){
  //   let date = form.find('.js-available-dates')
  //   date.removeClass('error')
  //   if (!date.find('.input:checked').val()){
  //     validated = 0;
  //     if (set_error) date.addClass('error')
  //   }
  // }

  return validated
}

$('form').on('reset', function (event) {
  let all_fields = $(this).find('.js-validate-text, .js-validate-password, .js-validate-password-repeat, .js-validate-checked, .js-validate-file, .js-validate-select')
  removeFieldStatusClasses(all_fields)
})

// dynamic field validation
export function validateInputOnChange(form) {
  // type text
  form.on('change', '.js-validate-text input', function () {
    validateTextFields($(this).closest('.js-validate-text'), true)
  })

  // passwords check
  form.on('change', '.js-validate-password input', function () {
    validateInputPassword($(this).closest('.js-validate-password'), true)
  })
  form.on('change', '.js-validate-password-repeat input', function () {
    validateInputPassword($(this).closest('.js-validate-password-repeat'), true)
  })

  // checkbox / radio
  form.on('change', '.js-validate-checked input', function () {
    validateChecked($(this).closest('.js-validate-checked'), true)
  })

  // files
  form.on('change', '.js-validate-file input', function () {
    validateInputFiles($(this).closest('.js-validate-file'), true)
  })

  // select
  form.on('change', '.js-validate-select select', function () {
    validateInputSelect($(this).closest('.js-validate-select'), true)
  })

}

// dynamical submit button appearance
export function allowSubmit(form) {
  let submit = form.find('input[type="submit"]')

  form.find('input').on('input', function () {
    if (validateForm(form, false)) {
      submit.removeClass('disabled')
    } else {
      submit.addClass('disabled')
    }
  })
}


//-- Validate Fields Functions --//

function setError (el) {
  el.addClass('error')
}
function setCorrect (el) {
  el.addClass('correct')
}
function removeFieldStatusClasses(el){
  el.removeClass('error')
  el.removeClass('correct')
}

// text
function validateTextFields(field_c, set_error) {
  let validated = 1
  let field = field_c.find('input')
  let val = field.val()
  if (set_error) removeFieldStatusClasses(field_c)

  if (field_c.hasClass('js-required')) {
    if (val === '') validated = 0
  }

  if (val) {
    if (field_c.hasClass('js-number')) {
      if (!$.isNumeric(val)) validated = 0
    }
    if (field_c.hasClass('js-email') && validated) {  // email
      if (!validEmail(val)) validated = 0
    }
    if (field_c.hasClass('js-length') && validated) {
      let length = field_c.data('length')
      if (val.length !== length) validated = 0
    }
    if (field_c.hasClass('js-min-value') && validated) {
      if (field_c.data('min-value') > val) validated = 0
    }
    if (field_c.hasClass('js-max-value') && validated) {
      if (field_c.data('max-value') < val) validated = 0
    }
    if (field_c.hasClass('js-phone') && val != '' && validated) {
      let filter = /^[\+|\d][\d]*$/
      if (String(val).search(filter) == -1) validated = 0
    }

    if (validated && set_error) setCorrect(field_c)
  }

  if (!validated && set_error) setError(field_c)

  return validated
}
//-- validate email
function validEmail (e) {
  var filter = /^\s*[\w\-\+_]+(\.[\w\-\+_]+)*\@[\w\-\+_]+\.[\w\-\+_]+(\.[\w\-\+_]+)*\s*$/
  return String(e).search(filter) != -1
}

// password
function validateInputPassword (field_c, set_error) {
  let validated = 1
  let field = field_c.find('input')
  let val = field.val()
  let form = field_c.closest('form')
  let passw_1 = form.find('.js-validate-password')
  let passw_2 = form.find('.js-validate-password-repeat')
  if (set_error) removeFieldStatusClasses(field_c)

  if (val === '') validated = 0

  // validate password security
  // https://www.w3resource.com/javascript/form/password-validation.php  - examples
  // let regex= /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
  // if (!val.match(regex)) validated = 0

  // check if equal passwords
  if (passw_1.length && passw_2.length) {
    let passw_1_val = passw_1.find('input').val()
    let passw_2_val = passw_2.find('input').val()

    if (passw_1_val && passw_2_val) {
      passw_1.removeClass('error')
      passw_2.removeClass('error')

      if (passw_1_val !== passw_2_val) {
        if (set_error) {
          setError(passw_1)
          setError(passw_2)
        }
        validated = 0
      }

      if (validated && set_error) setCorrect(passw_1)
      if (validated && set_error) setCorrect(passw_2)
    }
  }

  if (!validated && set_error) setError(field_c)
  return validated
}

// checkbox / radio
function validateChecked(field_c, set_error) {
  let validated = 1
  if (set_error) removeFieldStatusClasses(field_c)

  if (field_c.hasClass('js-required')) {
    if (!field_c.find('input:checked').length) validated = 0
  }

  if (!validated && set_error) setError(field_c)
  if (validated && set_error) setCorrect(field_c)
  return validated
}

// files
function validateInputFiles(field_c, set_error) {
  let validated = 1
  let input = field_c.find('input')
  if (set_error) removeFieldStatusClasses(field_c)

  if (field_c.hasClass('js-required')) {
    if (input[0 ].files.length === 0) validated = 0
  }

  if (!validated && set_error) setError(field_c)
  if (validated && set_error) setCorrect(field_c)
  return validated
}

// select
function validateInputSelect (field_c, set_error) {
  let validated = 1
  let input = field_c.find('select')
  if (set_error) removeFieldStatusClasses(field_c)

  if (field_c.hasClass('js-required')) {
    if (!input.val()) validated = 0
  }

  if (!validated && set_error) setError(field_c)
  if (validated && set_error) setCorrect(field_c)
  return validated
}
