import {validateForm, validateInputOnChange, allowSubmit} from '../forms/form-validation'

// Form Html Example For Default Form Automatic Submit
//------------------------------------------------------------------------
//<form class="js-default-form-submit">
//    <input type="hidden" name="action" value="" >
//    <input type="hidden" class="js-default-submit-completed" >
//
//    <div class="js-form-content" >
//      <div class="js-form-input js-required" >
//        <input type="text" name="name" id="name">
//      </div>
//    </div>
//    <div class="js-error-message js-message none"></div>
//    <div class="js-success-message js-message none"></div>
//
//    <button type="submit">Submit</button>
//</form>
//
// -- Detect Default Form Submit, to perform form specific logics --
// $('.js-form-name .js-default-submit-completed').change(function(){ });
//-------------------------------------------------------------------------

let defaultForm = $('.js-default-form-submit')
if (defaultForm.length) {
  defaultForm.each(function(){
    let form = $(this)
    validateInputOnChange(form)
    //allowSubmit(form)

    form.submit( function(e){
      e.preventDefault()
      let validated = 1
      validated = validateForm(form, true)

      if (validated === 1) {
        form.find('.js-message').hide()
        let formData = new FormData(this)
        //formData.append('g-recaptcha-response', grecaptcha.getResponse());

        $.ajax({
          type: "POST",
          url: ajaxurl,
          data: formData,
          enctype: 'multipart/form-data',
          contentType: false,
          processData: false,
          success: function (results) {
            if (results.error_message) {
              form.find('.js-error-message').html(results.error_message).fadeIn()
            } else {

              form.find('.js-success-message').fadeIn()

              // change submitted input, to other js logics know that default form submit logic completed
              if (results.additional_data !== undefined)
                form.find('.js-default-submit-completed').val(results.additional_data).change()

              // reset form
              form[0].reset()

              // reset captcha v2
              //grecaptcha.reset();

              // // reset captcha v3
              // grecaptcha.execute('6Lc1P1UcAAAAAKX2WG9JFfYytpfb8wLvafc1_fTJ', { action: 'action_name' }).
              // then(function(token) {
              //   document.getElementById('g-recaptcha-response').value = token
              // })

            }
          }
        });
      } else {
        let $error_message = form.find('.js-error-message')
        $error_message.html($error_message.data('message')).fadeIn()
      }
      return false;
    }) // end submit event

  })
}
