import $ from 'jquery';

$(document).ready( ()=> {

  //---- Select -----
  function initSelect() {
    // $(".js-select").select2({
    //   dropdownPosition: 'below',
    //   width: 'resolve',
    //   minimumResultsForSearch: -1,
    //   language: {
    //     noResults: function (params) {
    //       return "";
    //     }
    //   }
    // });
    $('form').on('reset', function (event) {
      let form = $(this)
      setTimeout(function () {
        form.find('select').trigger('change')
      }, 10)
    });

  }


  //---- Datepicker ----
  function initDatePicker() {

    // $('.input-date input').datepicker({
    //   dayNamesMin: ["Sv", "P", "O", "T", "C", "P", "Se"],
    //   firstDay: 1,
    //   monthNames: ["Janvāris", "Februāris", "Marts", "Aprīlis", "Maijs", "Jūnijs", "Jūlijs", "Augusts", "Septembris", "Oktobris", "Novembris", "Decembris"],
    //   dateFormat: 'dd.mm.yy',
    // })

  }

  //-----  Files upload button ------------
  function initFileUpload() {

    let file_inputs_class = '.js-file-upload'
    let $fileInputs = $(file_inputs_class)
    let fileListArr = [];
    let msgDiv = '.js-input-file-name';
    let addedFilesDiv = '.js-selected-files'; // added files list
    let maxFileSize = 8; // mb
    let maxTotSize = 8;
    let maxFiles = 8;
    let extArr = ['jpeg', 'jpg', 'png', 'pdf', 'doc', 'docx', 'xls', 'xlsx'];
    let fileTooLarge = 'Faila izmērs ir pārāk liels!';
    let typeNotAllowed = 'Faila tips nav atļauts!';
    let tooManyFiles = 'Maksimālais failu skaits ir ' + maxFiles;
    let totSizeMsg = 'Pārsniegts maksimālais kopējais izmērs ' + maxTotSize + ' MB';
    let removeFile = 'Noņemt';

    $fileInputs.each(function () {
      let $input = $(this).find('input');
      let $files_c = $(this)

      $input.on('change', function (e) {
        let inputId = $(this).attr('id')
        let addedFiles = $files_c.find(addedFilesDiv);
        let msg = $files_c.find(msgDiv);
        let fileList = [];
        let validateFile = 1;
        let filesCount = 0;
        let totSize = 0;

        $(addedFiles).empty();
        msg.html('');

        for (let i = this.files.length - 1; i >= 0; i--) {
          filesCount++;
          let fileErr = '';
          let addFileToList = 1;

          // check file size
          let size = this.files[i].size / 1024 / 1024;
          totSize = totSize + size;
          if (size > maxFileSize) {
            validateFile = 0;
            addFileToList = 0;
            //msg.append(fileTooLarge);
            fileErr = fileTooLarge;
          }

          // file types
          let ext = this.files[i].name.split('.').pop().toLowerCase();
          if ($.inArray(ext, extArr) == -1) {
            validateFile = 0;
            addFileToList = 0;
            //msg.append(typeNotAllowed);
            fileErr = typeNotAllowed;
          }

          // files count
          if (filesCount > maxFiles) {
            validateFile = 0;
            addFileToList = 0;
            fileErr = tooManyFiles;
          }

          // file tot size
          if (totSize >= maxTotSize) {
            validateFile = 0;
            addFileToList = 0;
            fileErr = totSizeMsg;
          }

          // add to list if allowed
          if (addFileToList === 1) {
            fileList.push(this.files[i]);
            addedFiles.append('<div class="uploaded-el">' + this.files[i].name + '<span class="remove js-remove-file" el="' + this.files[i].name + '" >' + removeFile + '</span></div>');
          } else {
            addedFiles.append('<div class="uploaded-el wrong">' + this.files[i].name + ' <span> - ' + fileErr + '</span</div>');
          }
        }

        fileListArr[inputId] = { 'files': '', 'validated': 0 };
        fileListArr[inputId]['files'] = fileList;
        fileListArr[inputId]['validated'] = validateFile;

      });

    });

    // remove file
    $(document).on('click', '.js-remove-file', function () {
      let $input = $(this).closest(file_inputs_class).find('input[type="file"]')
      let inputId = $input.attr('id');
      let removeEl = $(this).attr('el');
      // remove from files list
      fileListArr[inputId]['files'].splice(fileListArr[inputId]['files'].indexOf(removeEl), 1);
      $(this).parent().remove();
      // clear input field
      $input[0].value = '';
    });

    // clear file button on form reset
    $('form').on('reset', function (event) {
      $(this).find('.js-file-upload').find('.js-selected-files').html('')
    })

  }
// ---- End Files Upload Button ---------------------


  function initFormElements(){
    initSelect()
    initDatePicker()
    initFileUpload()
  }
  initFormElements()
})
